'use client';
import Head from 'next/head';
import React from 'react';
import { usePathname } from '@mentimeter/next-navigation';
import {
  FavIcons,
  TwitterMeta,
  AppleTouchIcons,
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  DEFAULT_IMAGE,
  getLocalizedPathname,
  removeTrailingSlash,
} from '@mentimeter/html-head-elements';
import { useTranslation } from 'react-i18next';
import { LOCALES, DEFAULT_LOCALE } from 'src/constants.cjs';
import { isReviewPhase } from '../phase';
import androidchrome192x192 from '../favicons/android-chrome-192x192.png';
import mstile144x144 from '../favicons/mstile-144x144.png';

// https://developers.google.com/search/docs/advanced/crawling/consolidate-duplicate-urls
// Use absolute paths rather than relative paths with the rel="canonical" link element.
const canonicals = [
  {
    path: '/features/quiz-presentations',
    duplicates: [
      '/features/quiz-presentations-1',
      '/features/quiz-presentations-2',
      '/features/quiz-presentations-3',
      '/features/quiz-presentations-4',
    ],
  },
];

const pipe =
  (...fns: Array<(x: string) => string>) =>
  (x: string) =>
    fns.reduce((v, f) => f(v), x);

const isContentfulImage = (url: string) =>
  url.includes('//images.ctfassets.net');

const prefixContentfulImageWithHttps = (url: string) =>
  isContentfulImage(url) ? ['https:', url].join('') : url;

// play with this -> https://images.ctfassets.net/rvt0uslu5yqp/6YNBgtiFONtKosg083CLaE/7df131df2b6654b9607730dc84d6168f/Doggo.png?w=1200&h=630&q=90&fit=pad&fm=jpg&bg=rgb:E9EBEE
// https://www.contentful.com/blog/2019/10/31/webp-source-sets-images-api/
// https://iamturns.com/open-graph-image-size/

const compressContentfulOgImage = (url: string) =>
  isContentfulImage(url) ? [url, 'w=1200&h=630&q=90&fm=jpg'].join('?') : url;

// play with this -> https://images.ctfassets.net/rvt0uslu5yqp/6YNBgtiFONtKosg083CLaE/7df131df2b6654b9607730dc84d6168f/Doggo.png?w=600&h=300&fm=png&fit=thumb&f=face
// https://www.contentful.com/blog/2019/10/31/webp-source-sets-images-api/
// https://iamturns.com/open-graph-image-size/

const compressContentfulTwitterImage = (url: string) =>
  isContentfulImage(url)
    ? [url, 'w=600&h=300&fm=png&fit=thumb&f=face'].join('?')
    : url;

interface Props {
  title?: string | undefined;
  description?: string | undefined;
  image?: string | undefined;
  siteUrl: string;
  renderOgUrl?: boolean | undefined;
}

const _Head = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  image = DEFAULT_IMAGE,
  siteUrl,
  renderOgUrl = true,
}: Props) => {
  const pathname = usePathname();
  const { i18n } = useTranslation();
  const { language: locale } = i18n;
  const canonicalPath = canonicals.find((c) =>
    c.duplicates.some((e) => e === pathname),
  )?.path;

  const canonicalDefaultPath = removeTrailingSlash(pathname);

  const ogImage = pipe(
    prefixContentfulImageWithHttps,
    compressContentfulOgImage,
  )(image);

  const twitterImage = pipe(
    prefixContentfulImageWithHttps,
    compressContentfulTwitterImage,
  )(image);

  return (
    <>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <title>{`${title} - Mentimeter`}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        {!isReviewPhase() && renderOgUrl && (
          <meta property="og:url" content={`${siteUrl}${pathname}`} />
        )}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Mentimeter" />
        <meta property="og:locale" content="en_US" />

        <meta property="fb:app_id" content="224322077663587" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content={mstile144x144.src} />
        <meta
          name="google-site-verification"
          content="mi8UihJ62TwmSIWzo3T2hTonoldlWaR0UscpqRKDVcA"
        />
        <meta name="google" content="notranslate" />

        {LOCALES.filter((locale: string) => DEFAULT_LOCALE !== locale).map(
          (locale: string) => (
            <link
              key={locale}
              rel="alternate"
              hrefLang={locale.split('-')[0]}
              href={`https://www.mentimeter.com/${locale}${pathname}`}
            />
          ),
        )}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://www.mentimeter.com${pathname}`}
        />

        {!isReviewPhase() && renderOgUrl && (
          <link
            rel="canonical"
            href={`https://www.mentimeter.com${getLocalizedPathname(
              canonicalPath || canonicalDefaultPath,
              locale,
              DEFAULT_LOCALE,
            )}`}
          />
        )}
      </Head>
      <TwitterMeta
        title={title}
        description={description}
        image={twitterImage}
      />
      <FavIcons>
        <link
          rel="icon"
          sizes="16x16"
          type="image/png"
          href={androidchrome192x192.src}
        />
        <link
          rel="mask-icon"
          sizes="192x192"
          color="#5bbad5"
          href={mstile144x144.src}
        />
      </FavIcons>
      <AppleTouchIcons />
    </>
  );
};

export default _Head;
