'use client';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Form,
  TextInputItem,
  Clickable,
  Text,
  type BoxT,
  type TextT,
} from '@mentimeter/ragnar-ui';
import {
  gray1100,
  gray200,
  gray600,
  purple100,
  purpleDark600,
  purpleDark800,
  whiteBase,
} from '@mentimeter/ragnar-colors';
import { gtmTrack } from '@mentimeter/google-tracking';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { getRegionByVoteId } from '@mentimeter/region';
import { getCoreURL } from '@api/fetch-support';
import { trackUser } from '@api/tracking/client';
import { trackVisitor } from './utils/trackVisitor';

const VOTING_URL = globalThis.__mentimeterEnv['NEXT_PUBLIC_VOTING_URL'];

export type VotingBarContext = 'Justvoted' | 'Homepage' | 'Signup';

interface Props {
  context: VotingBarContext;
}

const Narrow = (props: BoxT) => (
  <Box maxWidth="800px" width="100%" {...props} />
);

const P = (props: TextT) => (
  <Text as="p" fontSize={[2, 3]} mb={[2, 4]} {...props} />
);

const calculateBackgroundColor = (context: string) => {
  return context === 'Homepage' ? 'bg' : '#F4F4F6';
};

const VotingBar = ({ context }: Props) => {
  const [state, setState] = useState<undefined | 'error' | 'loading'>();
  const { t } = useTranslation('votingBarPackage');
  const votingBarRef = React.useRef<HTMLDivElement | null>(null);
  const [hideVotingBar, setHideVotingBar] = useState(false);

  const [rawVoteCode, setRawVoteCode] = React.useState<string>('');
  const voteId = rawVoteCode.replace(/\s/g, '');
  const isValidVotingId = voteId.length >= 4;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    void setRawVoteCode(event.target.value);

  const submitVoteCode = useCallback(
    async (voteId: string) => {
      setState('loading');
      try {
        const region = getRegionByVoteId(voteId);
        const coreURL = getCoreURL(region);

        const request = new Request(`${coreURL}/vote-ids/${voteId}/series`);
        const response = await fetch(request);

        if (!response.ok) {
          throw new Error('Failed to fetch series');
        }

        const data = (await response.json()) as { [key: string]: string };
        if (!data?.vote_key) {
          throw new Error('series missing in result');
        }

        gtmTrack({
          event: 'joinPresentation',
          type: data.closed_for_voting ? 'closed' : 'success',
        });

        trackVisitor({
          event: 'Clicked voting bar button',
          properties: { context, status: 'success' },
        });

        trackUser({
          event: 'Clicked voting bar button',
          properties: { context, status: 'success' },
        });

        window.location.assign(`${VOTING_URL}/${data.vote_key}`);
      } catch (e) {
        gtmTrack({
          event: 'joinPresentation',
          type: 'fail',
        });

        trackVisitor({
          event: 'Clicked voting bar button',
          properties: { context, status: 'error' },
        });
        setState('error');
        setTimeout(() => setState(undefined), 2000);
      }
    },
    [context],
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      if (isValidVotingId) {
        submitVoteCode(voteId);
      }
    },
    [isValidVotingId, voteId, submitVoteCode],
  );

  if (hideVotingBar) return null;

  return (
    <Box width="100%" bg={calculateBackgroundColor(context)}>
      <Box
        height={['98px', '60px']}
        bg={purple100}
        borderBottomLeftRadius="2xl"
        borderBottomRightRadius="2xl"
        width="100%"
        alignItems="center"
        justifyContent="center"
        px={[4, null, null, 6]}
        ref={votingBarRef}
        zIndex={9}
      >
        <Narrow alignItems="center" justifyContent="center">
          <Form
            display="flex"
            data-testid="enter-code-to-vote-form"
            flexDirection={['column', 'row']}
            alignItems="center"
            justifyContent="center"
            onSubmit={onSubmit}
          >
            <P
              fontSize="112.5"
              lineHeight="body"
              mb={[2, 0]}
              mr={[0, 3]}
              textAlign="center"
              color={purpleDark800}
              extend={() => ({
                whiteSpace: 'nowrap',
              })}
            >
              {t('enter_code_to_vote')}
            </P>
            <Box flexDirection="row">
              <TextInputItem
                aria-label={t('enter_code_here')}
                data-testid="enter-code-to-vote-input"
                type="tel"
                value={rawVoteCode}
                name="id"
                id="enter-vote-key"
                placeholder="1234 5678"
                onChange={handleChange}
                mr={2}
                maxLength={12} // You can enter a max of 12 chars ("12 34 56 78 ")
                inputSize="compact"
                extend={() => ({
                  border: '1px solid #DBDCE1',
                  boxShadow: 'none !important',
                  backgroundColor: whiteBase,
                  maxWidth: '104px',
                  textAlign: 'center',
                  ':focus': {
                    border: '2px solid ' + purpleDark600,
                    marginTop: '-1px',
                    marginBottom: '-1px',
                  },
                })}
              />
              <Button
                data-testid="enter-code-to-vote-button"
                aria-label={t('continue')}
                type="submit"
                disabled={!isValidVotingId}
                state={state}
                variant="primary"
                extend={() => ({
                  ':disabled': {
                    cursor: 'default',
                    background: whiteBase,
                    color: gray1100,
                  },
                  ':hover:disabled': {
                    background: gray200,
                    color: gray600,
                  },
                })}
              >
                {t('join')}
              </Button>
            </Box>
          </Form>
        </Narrow>
        <Clickable
          display={['none', null, 'initial']}
          position="absolute"
          right="2rem"
          aria-label={t('close_menu')}
          onClick={() => setHideVotingBar(true)}
        >
          <CrossIcon size={3} />
        </Clickable>
      </Box>
    </Box>
  );
};
export default VotingBar;
